import React from "react"

import { contain, heading, subhead } from "./splash.module.css"


export default function Splash() {
    return (
        <div className={contain}>
            <h1 className={heading}>Unitilator</h1>
            <img alt="" src="img/splash_logo.png"/>
            <h2 className={subhead}>A simple unit price calculator for Android</h2>
            <a href="https://play.google.com/store/apps/details?id=net.voidynullness.unitilator"
               target="_blank"
               rel="noopener noreferrer">
                <img alt='Get it on Google Play'
                     src='https://play.google.com/intl/en/badges/images/generic/en_badge_web_generic.png'
                     width="240"
                />
            </a>
        </div>
    );
}
