import React from "react"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Link from '../components/Link';
import Splash from "../components/splash"


const IndexPage = () => {
    return (
        <div>
            <Splash />
            <Container maxWidth="sm">
                <Typography variant="subtitle1" color="textSecondary" align="center">
                    <p><Link to="/about">About unit prices</Link></p>
                </Typography>
            </Container>
        </div>
    )
};


export default IndexPage
